<template>
  <div data-gallery-vertical class="grid grid-cols-12 gap-3 sm:gap-4">
    <div
      v-for="(slide, index) in slides"
      :key="index"
      :class="getColClassList(index)"
    >
      <component
        :is="displayLink(slide) ? NuxtLink : 'div'"
        v-bind="displayLink(slide) ? slide.link : { class: 'contents' }"
      >
        <GalleryImage
          :image="slide.image"
          :image-config
          :image-format="
            imageFormat === GALLERY_IMAGE_FORMAT.ORIGINAL
              ? slide.image.format
              : imageFormat
          "
          :class="cssClasses.image"
          @click="displayLink(slide) ? '' : openModal(index)"
        />
      </component>
      <div>
        <div
          v-if="!hideTitle && slide.title"
          :class="cssClasses.title"
          v-text="slide.title"
        />
        <div
          v-if="!hideDescription && slide.description"
          :class="cssClasses.description"
          v-text="slide.description"
        />
      </div>
    </div>

    <LazyImageModal
      v-if="allowZoom && isImageModalVisible"
      v-bind="slides[currentSlide]"
      @close="isImageModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
export interface GalleryVerticalProps {
  imageSize?: GalleryImageSize
  imageFormat?: GalleryImageFormat
  renderAboveTheFold?: boolean
  pattern?: GalleryPattern
  hideTitle?: boolean
  hideDescription?: boolean
  hideLink?: boolean
  allowZoom?: boolean
  slides: GallerySlide[]
  classes?: {
    title?: string | object | []
    description?: string | object | []
    image?: string | object | []
  }
}

const NuxtLink = resolveComponent('NuxtLink')

const props = withDefaults(defineProps<GalleryVerticalProps>(), {
  pattern: GALLERY_PATTERN.EVEN,
  imageSize: GALLERY_IMAGE_SIZE.XS,
  imageFormat: GALLERY_IMAGE_FORMAT.LANDSCAPE,
  hideTitle: false,
  hideDescription: false,
  hideLink: false,
  allowZoom: false,
  classes: () => ({}),
})

const cssClasses = computed(() => {
  const { title, description, image } = props.classes

  return {
    title:
      title ??
      'font-medium mt-1 leading-tight text-sm text-center text-ellipsis overflow-hidden',
    description:
      description ??
      'text-xs leading-tight text-center text-ellipsis overflow-hidden',
    image: image ?? { 'cursor-pointer': props.allowZoom },
  }
})

const isImageModalVisible = ref(false)
const currentSlide = ref(0)

function displayLink(slide: GallerySlide) {
  return !props.allowZoom && !props.hideLink && slide.link?.to
}

function openModal(index: number) {
  currentSlide.value = index
  isImageModalVisible.value = true
}

function getColClassList(index: number) {
  let colClass = {}

  switch (props.pattern) {
    case GALLERY_PATTERN.EVEN:
      colClass = {
        'col-span-12 sm:col-span-6': props.imageSize === GALLERY_IMAGE_SIZE.LG,
        'col-span-6 md:col-span-4': props.imageSize === GALLERY_IMAGE_SIZE.MD,
        'col-span-4 md:col-span-3': props.imageSize === GALLERY_IMAGE_SIZE.SM,
        'col-span-4 sm:col-span-3 md:col-span-2':
          props.imageSize === GALLERY_IMAGE_SIZE.XS,
      }
      break

    case GALLERY_PATTERN.AUTO: {
      const { format } = props.slides[index].image
      colClass = {
        'col-span-4 sm:col-span-3 row-span-2': format === IMAGE_FORMAT.PORTRAIT,
        'col-span-6 row-span-1':
          format === IMAGE_FORMAT.WIDESCREEN ||
          format === IMAGE_FORMAT.LANDSCAPE,
      }
      break
    }

    case GALLERY_PATTERN.ALTERNATE:
      if (props.imageSize === GALLERY_IMAGE_SIZE.LG) {
        colClass = {
          'col-span-6': index % 3 !== 2,
          'col-span-12': index % 3 === 2,
        }
      } else if (props.imageSize === GALLERY_IMAGE_SIZE.MD) {
        colClass = {
          'md:col-span-4': index % 5 < 3,
          'md:col-span-6': index % 5 >= 3,
          'col-span-6': index % 3 !== 2,
          'col-span-12': index % 3 === 2,
        }
      } else if (props.imageSize === GALLERY_IMAGE_SIZE.SM) {
        colClass = {
          'md:col-span-3': index % 7 < 4,
          'md:col-span-4': index % 7 >= 4,
          'sm:col-span-4': index % 5 < 3,
          'sm:col-span-6': index % 5 >= 3,
          'col-span-4': index % 5 < 3,
          'col-span-6': index % 5 >= 3,
        }
      } else if (props.imageSize === GALLERY_IMAGE_SIZE.XS) {
        colClass = {
          'md:col-span-2': index % 10 < 6,
          'md:col-span-3': index % 10 >= 6,
          'col-span-4': index % 5 < 3,
          'col-span-6': index % 5 >= 3,
        }
      }
      break
  }

  return colClass
}

const config = useAppConfig()
const imageConfig = computed(() => {
  let sizes

  if (GALLERY_IMAGE_SIZE.XS) {
    sizes = '104px lg:157px xlg:115px'
  }

  if (GALLERY_IMAGE_SIZE.SM) {
    sizes = '104px lg:157px xlg:115px'
  }

  if (GALLERY_IMAGE_SIZE.MD) {
    sizes = '104px lg:157px xlg:115px'
  }

  if (GALLERY_IMAGE_SIZE.LG) {
    sizes = '584px sm:273px md:337px lg:465px xlg:528px'
  }

  return {
    ...config.images.gallery.vertical,
    sizes,
  }
})

defineOptions({
  name: 'GalleryVertical',
})
</script>
