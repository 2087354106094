<template>
  <div data-gallery-carousel>
    <Carousel v-bind="props" />
  </div>
</template>

<script setup lang="ts">
export interface GalleryCarouselProps {
  renderAboveTheFold: boolean
  renderTrailer?: boolean
  activeSlide?: number
  autoplay?: boolean
  infinite?: boolean
  slideDuration?: number
  hideTitle?: boolean
  hideDescription?: boolean
  hideLink?: boolean
  slides: GallerySlide[]
  classes?: {
    title?: string | object | []
    description?: string | object | []
    image?: string | object | []
    buttonPrev?: string | object | []
    buttonNext?: string | object | []
    slide?: string | object | []
  }
}

const props = defineProps<GalleryCarouselProps>()

defineOptions({
  name: 'GalleryCarousel',
})
</script>
