import type { Slide } from '#gql/default'

export default function useGallery() {
  function mapGallerySlides(slides: Slide[]) {
    return slides.map((slide) => ({
      title: slide.title,
      description: slide.description,
      image: getImage(slide, 'image', slide.title ?? ''),
      link: slide.link
        ? {
            to: slide.link.url,
            title: slide.link.title,
            target: slide.link.target,
          }
        : null,
    }))
  }

  return {
    mapGallerySlides,
  }
}
